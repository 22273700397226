<script setup>
import CompanyInfoTab1 from "../components/tabs/CompanyInfoTab1.vue";
import CompanyInfoTab2 from "../components/tabs/CompanyInfoTab2.vue";
import CompanyInfoTab3 from "../components/tabs/CompanyInfoTab3.vue";
import CompanyInfoTab4 from "../components/tabs/CompanyInfoTab4.vue";
</script>
<template>
  <div>
    <div class="title" style="margin-top: 50px; padding: 0 20px">
      <v-row align="center" no-gutters style="height: 100px">
        <v-col cols="8">
          <v-sheet class="pa-2 ma-2">
            <h1>{{ companyInfo.corporate_name }}</h1>
            <p>{{ companyInfo.insurer_name }}</p>
          </v-sheet>
        </v-col>
      </v-row>
      <v-divider
        :thickness="3"
        class="border-opacity-75"
        color="#009688"
      ></v-divider>
      <div class="my-3">
        <v-chip label class="mr-2">{{ companyInfo.industry_name }}</v-chip>
        <v-chip
          label
          variant="outlined"
          color="red-darken-4"
          class="mr-2"
          v-if="companyInfo.listed_status"
          >上場</v-chip
        >
        <v-chip
          label
          variant="outlined"
          color="lime-darken-3"
          class="mr-2"
          v-if="companyInfo.brand_flag"
          >健康経営銘柄</v-chip
        >
        <v-chip
          label
          variant="outlined"
          color="primary"
          class="mr-2"
          v-if="companyInfo.white500_flag"
          >ホワイト500</v-chip
        >
        <v-chip
          label
          variant="outlined"
          color="primary"
          class="mr-2"
          v-if="companyInfo.big_good_flag"
          >優良法人（大）</v-chip
        >
        <v-chip
          label
          variant="outlined"
          color="primary"
          class="mr-2"
          v-if="companyInfo.bright500_flag"
          >ブライト500</v-chip
        >
        <v-chip
          label
          variant="outlined"
          color="primary"
          class="mr-2"
          v-if="companyInfo.small_good_flag"
          >優良法人（中小）</v-chip
        >
      </div>
    </div>
    <div style="text-align: left" class="mx-4 my-12">
      <!-- <button @click="openPDF" style="background-color: #009688; color: white; border-radius: 50px; padding: 10px 20px; border: none; cursor: pointer;">PDFを表示する</button> -->
      <a
        href="#"
        @click="downloadPDF"
        style="
          background-color: #009688;
          color: white;
          border-radius: 1.25rem;
          padding: 10px 20px;
          border: none;
          cursor: pointer;
          text-decoration: none;
        "
        >フィードバックシートをダウンロード</a
      >
    </div>
    <div class="companyInfo">
      <v-card>
        <v-tabs v-model="tab" color="#009688" align-tabs="center" grow>
          <v-tab
            :value="1"
            style="font-size: large"
            prepend-icon="mdi-chart-bar"
            >評価結果</v-tab
          >
          <v-tab
            :value="2"
            style="font-size: large"
            prepend-icon="mdi-clipboard-outline"
            >健康経営の戦略</v-tab
          >
          <v-tab
            :value="3"
            style="font-size: large"
            prepend-icon="mdi-timelapse"
            >行動指標</v-tab
          >
          <v-tab
            :value="4"
            style="font-size: large"
            prepend-icon="mdi-checkbox-multiple-marked-outline"
            >課題への対応</v-tab
          >
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item v-for="n in 4" :key="n" :value="n">
            <v-container fluid v-if="n === 1">
              <!-- タブ１：評価結果 -->
              <CompanyInfoTab1 :companyInfo="companyInfo" />
            </v-container>
            <v-container fluid v-if="n === 2">
              <!-- タブ２：健康経営の戦略 -->
              <CompanyInfoTab2 :companyInfo="companyInfo" />
            </v-container>
            <v-container fluid v-if="n === 3">
              <!-- タブ３：行動指標 -->
              <CompanyInfoTab3 :companyInfo="companyInfo" />
            </v-container>
            <v-container fluid v-if="n === 4">
              <!-- タブ４：課題への対応 -->
              <CompanyInfoTab4 :companyInfo="companyInfo" />
            </v-container>
          </v-window-item>
        </v-window>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    tab: null,
    companyInfo: {
      code: 0, // コード
      corporate_name: null, // 法人名
      english_corporate_name: null, // 英文法人名
      listed_status: false, // 上場フラグ
      brand_flag: false, // 健康経営銘柄フラグ
      white500_flag: false, // ホワイト500フラグ
      big_good_flag: false, // 優良法人（大）フラグ
      bright500_flag: false, // ブライト500フラグ
      small_good_flag: false, // 優良法人（中小）フラグ
      industry_code: 0, // 業種番号
      industry_name: null, // 業種名
      overall_lank: null, // 総合順位
      overall_evaluation: 0, // 総合評価
      overall_deviation_value: 0, // 総合偏差値
      aspect1: 0, // 経営理念・方針偏差値
      aspect2: 0, // 組織体制偏差値
      aspect3: 0, // 評価・施策実行偏差値
      aspect4: 0, // 評価改善偏差値
      industry_deviation_value: 0, // 総合偏差値の業種平均
      industry_aspect1: 0, // 経営理念・方針偏差値の業種平均
      industry_aspect2: 0, // 組織体制偏差値の業種平均
      industry_aspect3: 0, // 評価・施策実行偏差値の業種平均
      industry_aspect4: 0, // 評価改善偏差値の業種平均
      last_year_overall_rank: null, // 1年前の結果
      last_year_oerall_deviation_value: 0,
      last_year_aspect1: 0,
      last_year_aspect2: 0,
      last_year_aspect3: 0,
      last_year_aspect4: 0,
      two_years_ago_overall_rank: null, // 2年前の結果
      two_years_ago_oerall_deviation_value: 0,
      two_years_ago_aspect1: 0,
      two_years_ago_aspect2: 0,
      two_years_ago_aspect3: 0,
      two_years_ago_aspect4: 0,
      three_years_ago_overall_rank: null, // 3年前の結果
      three_years_ago_oerall_deviation_value: 0,
      three_years_ago_aspect1: 0,
      three_years_ago_aspect2: 0,
      three_years_ago_aspect3: 0,
      three_years_ago_aspect4: 0,
      four_years_ago_overall_rank: null, // 4年前の結果
      four_years_ago_oerall_deviation_value: 0,
      four_years_ago_aspect1: 0,
      four_years_ago_aspect2: 0,
      four_years_ago_aspect3: 0,
      four_years_ago_aspect4: 0,
      detail1_1: 0, // 評価項目詳細偏差値
      detail1_2: 0,
      detail2_1: 0,
      detail2_2: 0,
      detail2_3: 0,
      detail3_1: 0,
      detail3_2: 0,
      detail3_3: 0,
      detail3_4: 0,
      detail3_5: 0,
      detail4_1: 0,
      detail4_2: 0,
      detail4_3: 0,
      industry_detail1_1: 0, // 評価項目詳細偏差値の業種平均
      industry_detail1_2: 0,
      industry_detail2_1: 0,
      industry_detail2_2: 0,
      industry_detail2_3: 0,
      industry_detail3_1: 0,
      industry_detail3_2: 0,
      industry_detail3_3: 0,
      industry_detail3_4: 0,
      industry_detail3_5: 0,
      industry_detail4_1: 0,
      industry_detail4_2: 0,
      industry_detail4_3: 0,
      insurer_name: null, // 保険協会名
    },
  }),
  methods: {
    async downloadPDF() {
      try {
        // APIを呼び出してPDFデータを取得
        const response = await axios.get(
          "https://welcomeapi.armdxp.com/EXTRACT_PDF?code=" +
            this.$route.params.code,
          {
            responseType: "blob",
          }
        );

        // ダウンロード用のURLを作成し、リンク要素をクリックしてダウンロードをトリガーする
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "extracted_pdf.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // URLオブジェクトの解放
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("PDFのダウンロードに失敗しました:", error);
      }
    },
  },
  mounted() {
    axios
      .get(
        "https://welcomeapi.armdxp.com/DETAIL_1?code=" + this.$route.params.code
      )
      .then((response) => {
        this.companyInfo = response.data;
      });
  },
};
</script>
