<template>
	<v-row>
		<v-col>
			<h2>① 健康経営度評価結果</h2>
			<h3>
				■ 総合順位：
				<span style="color: #B71C1C;font-size: 1.5rem;">
					{{ companyInfo.overall_rank }} / {{ num_of_company }}社中
				</span>
			</h3>
			<h3>
				■ 評価結果：
				<span style="color: #B71C1C;font-size: 1.5rem;">
					{{ companyInfo.overall_deviation_value }}
				</span>
				<span style="color: #B71C1C;font-size: 1rem;">
					（<span v-if="(companyInfo.overall_deviation_value - companyInfo.last_year_overall_deviation_value) > 0">↑</span><span v-else >↓</span>
					{{ Math.round((companyInfo.overall_deviation_value - companyInfo.last_year_overall_deviation_value)*10)/10 }}）前回偏差値 {{ companyInfo.last_year_overall_deviation_value }}
				</span>
			</h3>
			<p>
				※「ホワイト500」に該当するかについては、回答法⼈全体ではなく申請法⼈に絞った順位で判定するため、
				フィードバックシートの順位から繰り上がり認定される場合があります。
			</p>
		</v-col>
	</v-row><br><br>
	<v-row>
		<v-col cols="12">
			<h2>② 評価の内訳</h2>
		</v-col>
		<v-col cols="10">
			<v-table>
				<thead>
					<tr>
						<th class="text-left" width="40%">側面</th>
						<th class="text-left" width="20%">重み</th>
						<th class="text-left" width="20%"><b>貴社</b></th>
						<th class="text-left" width="20%">業界平均</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item, index) in desserts"
						:key="item.name"
						@click="toggleRow(item.name, index)"
						style="cursor: pointer;"
					>
						<th>
							<template v-if="item.question">
								<span>{{ item.name }}</span><br>
								<a href="#" class="question" @click.prevent="showQuestion(item)">対応Q番号</a>
							</template>
							<template v-else>
								{{ item.name }}
							</template>
						</th>
						<td>{{ item.weight }}</td>
						<td><b>{{ item.companyRank }}</b></td>
						<td>{{ item.avarageRankIndustory }}</td>
					</tr>
				</tbody>
			</v-table>
			<p>
				※各側⾯の数値は、回答法⼈全体の平均に基づく偏差値です。各側⾯の数値に重みを掛けた値を合算して、得点・順位を算出しています。<br>
				※トップの数値は項⽬毎の最⾼値です。なお、所属業種の有効回答が5社未満の場合、業種トップには「-」を表⽰しています。
			</p>
		</v-col>
	</v-row><br><br>
	<v-row>
		<v-col cols="12">
			<h2>③ 評価の変遷（直近5回の評価結果）</h2>
		</v-col>
		<v-col cols="8">
			<v-table>
				<thead>
					<tr>
						<th class="text-left" width="25%"></th>
						<th class="text-left" width="15%">令和5年度</th>
						<th class="text-left" width="15%">令和4年度</th>
						<th class="text-left" width="15%">令和3年度</th>
						<th class="text-left" width="15%">令和2年度</th>
						<th class="text-left" width="15%">令和1年度</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="item in desserts2"
						:key="item.name"
					>
						<th>{{ item.name }}</th>
						<td>{{ item.thisYear }}</td>
						<td>{{ item.oneYearsAgo }}</td>
						<td>{{ item.twoYearsAgo }}</td>
						<td>{{ item.threeYearsAgo }}</td>
						<td>{{ item.fourYearsAgo }}</td>
					</tr>
				</tbody>
			</v-table>
			<p>
				※総合評価の偏差値推移の括弧内は、前年度との⽐較を⽰しています。
			</p>
		</v-col>
		<v-dialog v-model="dialog" max-width="1000">
			<!-- ポップアップの内容 -->
			<v-card>
				<v-toolbar color="blue-grey">
					<v-toolbar-title>{{ this.modalTitle }}</v-toolbar-title>
				</v-toolbar>
					<div>
						<v-tabs
							v-model="questionTab"
						>
							<v-tab
								v-for="(tabQuestion, index) in selectedQuestion.list"
								:key="tabQuestion.name"
								@click="changeQuestionImg(this.selectedQuestion.list[index])"
							>
								{{ this.selectedQuestion.list[index] }}
							</v-tab>
						</v-tabs>
					</div>
				<v-tabs-items v-model="questionTab">
					<v-tabs-items
						:value="this.selectedQuestion.text"
					>
						<v-card-text>
							<b>{{ this.questionDetail }}</b>
						</v-card-text>
						<img :src="this.imageSrc" style="width: 100%;" alt="Question Image"/>
					</v-tabs-items>
				</v-tabs-items>
			</v-card>
		</v-dialog>
	</v-row>
	<br><br>
</template>
<script>
import questionDetails from "../../../data/question_details.js";
export default {
	props: ["companyInfo"],
	data: () => ({
		tab: null,
		num_of_company: 3169,
		desserts: [
			{
				name: '総合評価',
				weight: '-',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '経営理念・⽅針',
				weight: 3,
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '組織体制',
				weight: 2,
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '制度・施策実行',
				weight: 2,
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '評価・改善',
				weight: 3,
				companyRank: 0,
				avarageRankIndustory: 0,
			},
		],
		desserts2: [
			{
				name: '総合順位',
				thisYear: null,
				oneYearsAgo: null,
				twoYearsAgo: null,
				threeYearsAgo: null,
				fourYearsAgo: null,
			},
			{
				name: '総合評価',
				thisYear: 0,
				oneYearsAgo: 0,
				twoYearsAgo: 0,
				threeYearsAgo: 0,
				fourYearsAgo: 0,
			},
			{
				name: '【参考】回答企業数',
				thisYear: '3520社',
				oneYearsAgo: '3169社',
				twoYearsAgo: '2869社',
				threeYearsAgo: '2523社',
				fourYearsAgo: '2328社',
			},
		],
		details: [
			{
				name: '経営理念・方針',
				weight: '明文化・健康経営の戦略',
				questinos: 'Q17、Q18、Q20',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '',
				weight: '情報開示・他社への普及',
				questinos: 'Q19、Q22、Q23',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '組織体制',
				weight: '経営層の関与',
				questinos: 'Q26、Q27',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '',
				weight: '実施体制',
				questinos: 'Q28、Q29、Q30、Q32、Q33',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '',
				weight: '従業員への浸透',
				questinos: 'Q34、Q35、Q36',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '制度・施策実行',
				weight: '目標設定、健診・検診等の活用',
				questinos: 'Q37、Q38、Q39、Q40、Q41',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '',
				weight: '健康経営の実践に向けた土台づくり',
				questinos: 'Q42、Q43、Q44、Q45、Q46、Q47、Q48、Q49',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '',
				weight: '保険指導',
				questinos: 'Q50、Q51、Q52、Q53',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '',
				weight: '生活習慣の改善',
				questinos: 'Q54、Q55、Q56、Q64、Q65',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '',
				weight: 'その他の施策',
				questinos: 'Q57、Q58、Q59、Q60、Q61、Q62、Q63',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '評価改善',
				weight: '健康診断・ストレスチェック',
				questinos: 'Q66、Q67、Q68、Q72',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '',
				weight: '労働時間・休職',
				questinos: 'Q69、Q70、Q71',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
			{
				name: '',
				weight: '課題単位・施策全体の効果検証・改善',
				questinos: 'Q73、Q74',
				companyRank: 0,
				avarageRankIndustory: 0,
			},
		],
		dialog: false,
		selectedQuestion: {
			text: '',
			list: []
		},
		questionTab: null,
		imageSrc: "",
		questionDetail: "",
		modalTitle: "",
	}
),
	created() {
		// companyInfoが渡された後にdessertsのデータを更新
		this.updateDesserts();
	},
	methods: {
		updateDesserts() {
			// desserts更新
			this.desserts[0].companyRank = this.companyInfo.overall_deviation_value;
			this.desserts[0].avarageRankIndustory = this.companyInfo.industry_deviation_value;
			this.desserts[1].companyRank = this.companyInfo.aspect1;
			this.desserts[1].avarageRankIndustory = this.companyInfo.industry_aspect1;
			this.desserts[2].companyRank = this.companyInfo.aspect2;
			this.desserts[2].avarageRankIndustory = this.companyInfo.industry_aspect2;
			this.desserts[3].companyRank = this.companyInfo.aspect3;
			this.desserts[3].avarageRankIndustory = this.companyInfo.industry_aspect3;
			this.desserts[4].companyRank = this.companyInfo.aspect4;
			this.desserts[4].avarageRankIndustory = this.companyInfo.industry_aspect4;
			// desserts2更新
			this.desserts2[0].thisYear = this.companyInfo.overall_rank;
			this.desserts2[0].oneYearsAgo = this.companyInfo.last_year_overall_rank;
			this.desserts2[0].twoYearsAgo = this.companyInfo.two_years_ago_overall_rank;
			this.desserts2[0].threeYearsAgo = this.companyInfo.three_years_ago_overall_rank;
			this.desserts2[0].fourYearsAgo = this.companyInfo.four_years_ago_overall_rank;
			this.desserts2[1].thisYear = this.companyInfo.overall_deviation_value;
			this.desserts2[1].oneYearsAgo = this.companyInfo.last_year_overall_deviation_value;
			this.desserts2[1].twoYearsAgo = this.companyInfo.two_years_ago_overall_deviation_value;
			this.desserts2[1].threeYearsAgo = this.companyInfo.three_years_ago_overall_deviation_value;
			this.desserts2[1].fourYearsAgo = this.companyInfo.four_years_ago_overall_deviation_value;

			this.details[0].companyRank = this.companyInfo.detail1_1
			this.details[1].companyRank = this.companyInfo.detail1_2
			this.details[2].companyRank = this.companyInfo.detail2_1
			this.details[3].companyRank = this.companyInfo.detail2_2
			this.details[4].companyRank = this.companyInfo.detail2_3
			this.details[5].companyRank = this.companyInfo.detail3_1
			this.details[6].companyRank = this.companyInfo.detail3_2
			this.details[7].companyRank = this.companyInfo.detail3_3
			this.details[8].companyRank = this.companyInfo.detail3_4
			this.details[9].companyRank = this.companyInfo.detail3_5
			this.details[10].companyRank = this.companyInfo.detail4_1
			this.details[11].companyRank = this.companyInfo.detail4_2
			this.details[12].companyRank = this.companyInfo.detail4_3

			this.details[0].avarageRankIndustory = this.companyInfo.industry_detail1_1
			this.details[1].avarageRankIndustory = this.companyInfo.industry_detail1_2
			this.details[2].avarageRankIndustory = this.companyInfo.industry_detail2_1
			this.details[3].avarageRankIndustory = this.companyInfo.industry_detail2_2
			this.details[4].avarageRankIndustory = this.companyInfo.industry_detail2_3
			this.details[5].avarageRankIndustory = this.companyInfo.industry_detail3_1
			this.details[6].avarageRankIndustory = this.companyInfo.industry_detail3_2
			this.details[7].avarageRankIndustory = this.companyInfo.industry_detail3_3
			this.details[8].avarageRankIndustory = this.companyInfo.industry_detail3_4
			this.details[9].avarageRankIndustory = this.companyInfo.industry_detail3_5
			this.details[10].avarageRankIndustory = this.companyInfo.industry_detail4_1
			this.details[11].avarageRankIndustory = this.companyInfo.industry_detail4_2
			this.details[12].avarageRankIndustory = this.companyInfo.industry_detail4_3
			// 経営理念・方針
			// 組織体制
			// 制度・施策実行
			// 評価改善
		},
		toggleRow(name, index) {

			// まず、クリックされた行の次の行のインデックスを計算します
			const nextRowIndex = index + 1;

			// 新しい行を追加するかどうかを制御するフラグを設定します
			let addNewRows = true;
			let add_first_names = ["　- "+this.details[0].weight , "　- "+this.details[2].weight,"　- "+this.details[5].weight,"　- "+this.details[10].weight]

			if (nextRowIndex < this.desserts.length && (add_first_names.includes(this.desserts[nextRowIndex].name))) {
				if (this.desserts[nextRowIndex].name == "　- "+this.details[0].weight){
					this.desserts.splice(nextRowIndex, 2);
				}
				else if (this.desserts[nextRowIndex].name == "　- "+this.details[2].weight){
					this.desserts.splice(nextRowIndex, 3);
				}
				else if (this.desserts[nextRowIndex].name == "　- "+this.details[5].weight){
					this.desserts.splice(nextRowIndex, 5);
				}
				else if (this.desserts[nextRowIndex].name == "　- "+this.details[10].weight){
					this.desserts.splice(nextRowIndex, 3);
				}
				// 新しい行を追加しないようにフラグを設定します
				addNewRows = false;
			}
			// もしクリックされた行が 'a'、'b'、'c' の行だったら何もせずに終了します
			if (name != '経営理念・⽅針' && name != '組織体制' && name != '制度・施策実行' && name != '評価・改善') {
				return;
			}

			// 新しい行を追加するフラグが true の場合にのみ新しい行を追加します
			if (addNewRows) {
				if (name == '経営理念・⽅針'){
					this.desserts.splice(index + 1, 0, { name:"　- "+ this.details[0].weight, weight: '', companyRank: this.details[0].companyRank, avarageRankIndustory: this.details[0].avarageRankIndustory, question: this.details[0].questinos});
					this.desserts.splice(index + 2, 0, { name:"　- "+ this.details[1].weight, weight: '', companyRank: this.details[1].companyRank, avarageRankIndustory: this.details[1].avarageRankIndustory, question: this.details[1].questinos});
				}
				else if (name == '組織体制'){
					this.desserts.splice(index + 1, 0, { name:"　- "+ this.details[2].weight, weight: '', companyRank: this.details[2].companyRank, avarageRankIndustory: this.details[2].avarageRankIndustory, question: this.details[2].questinos});
					this.desserts.splice(index + 2, 0, { name:"　- "+ this.details[3].weight, weight: '', companyRank: this.details[3].companyRank, avarageRankIndustory: this.details[3].avarageRankIndustory, question: this.details[3].questinos});
					this.desserts.splice(index + 3, 0, { name:"　- "+ this.details[4].weight, weight: '', companyRank: this.details[4].companyRank, avarageRankIndustory: this.details[4].avarageRankIndustory, question: this.details[4].questinos});
				}
				else if (name == '制度・施策実行'){
					this.desserts.splice(index + 1, 0, { name:"　- "+ this.details[5].weight, weight: '', companyRank: this.details[5].companyRank, avarageRankIndustory: this.details[5].avarageRankIndustory, question: this.details[5].questinos});
					this.desserts.splice(index + 2, 0, { name:"　- "+ this.details[6].weight, weight: '', companyRank: this.details[6].companyRank, avarageRankIndustory: this.details[6].avarageRankIndustory, question: this.details[6].questinos});
					this.desserts.splice(index + 3, 0, { name:"　- "+ this.details[7].weight, weight: '', companyRank: this.details[7].companyRank, avarageRankIndustory: this.details[7].avarageRankIndustory, question: this.details[7].questinos});
					this.desserts.splice(index + 4, 0, { name:"　- "+ this.details[8].weight, weight: '', companyRank: this.details[8].companyRank, avarageRankIndustory: this.details[8].avarageRankIndustory, question: this.details[8].questinos});
					this.desserts.splice(index + 5, 0, { name:"　- "+ this.details[9].weight, weight: '', companyRank: this.details[9].companyRank, avarageRankIndustory: this.details[9].avarageRankIndustory, question: this.details[9].questinos});
				}
				else if (name == '評価・改善'){
					this.desserts.splice(index + 1, 0, { name:"　- "+ this.details[10].weight, weight: '', companyRank: this.details[10].companyRank, avarageRankIndustory: this.details[10].avarageRankIndustory, question: this.details[10].questinos});
					this.desserts.splice(index + 2, 0, { name:"　- "+ this.details[11].weight, weight: '', companyRank: this.details[11].companyRank, avarageRankIndustory: this.details[11].avarageRankIndustory, question: this.details[11].questinos});
					this.desserts.splice(index + 3, 0, { name:"　- "+ this.details[12].weight, weight: '', companyRank: this.details[12].companyRank, avarageRankIndustory: this.details[12].avarageRankIndustory, question: this.details[12].questinos});
				}
			}
		},
		showQuestion(item) {
			this.selectedQuestion.list = item.question.split('、');
			// this.selectedQuestion.listには各Qがリスト形式で格納されている
			this.selectedQuestion.text = this.selectedQuestion.list[0];
			this.questionTab = this.selectedQuestion.text;
			this.dialog = true;
			this.modalTitle = "【"+item.name.replace("　-","")+"】に対応した調査票の設問";
			this.changeQuestionImg(this.questionTab);
		},
		// 選択された質問に対する設問内容を取得するメソッド
		getQuestionDetails(question) {
			let filterd_question = question.substr(0,3)
			this.questionDetail = questionDetails.myMap.get(filterd_question) || '該当する設問がありません。';
		},
		changeQuestionImg(question) {
			let changedQuestionLowarCase = question.toLowerCase();
			this.imageSrc = require(`@/assets/question_img/${changedQuestionLowarCase.substring(0,3)}.png`);
			this.getQuestionDetails(question);
		},
	},
	watch: {
		companyInfo: {
			handler: 'updateDesserts', // companyInfoが変更されたらupdateDessertsメソッドを呼び出す
			immediate: true // 初期化時にも呼び出す
		}
	},
}
</script>
<style scoped>
.question {
		display: inline-block;
		float: right;
	text-decoration: none;
	color: #009688;
}
</style>