<template>
  <v-app>
    <v-app-bar color="white" dark app>
      <v-toolbar-title>
        <!-- <span @click="handleClick" style="cursor: pointer">WelCome</span> -->
        <div @click="handleClick">
          <v-img
            src="title.png"
            max-height="64"
            max-width="300"
            class="align-left"
            style="cursor: pointer"
          ></v-img>
        </div>
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
      width="300"
      permanent
      border="none"
      style="padding: 20px"
    >
      <v-list-item title="外部サイト"></v-list-item>
      <v-list-item
        v-for="nav in nav_lists"
        :key="nav.name"
        :href="nav.href"
        :prepend-avatar="nav.prependAvatarUrl"
      >
        <v-list-item-content class="d-flex align-center">
          <v-list-item-title style="font-size: 14px">{{
            nav.name
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list style="margin-top: 24px">
        <v-list-item><b>【保険組合】</b></v-list-item>
        <v-list-item>
          <v-combobox
            label="保険組合名"
            :items="filteredInsurerList"
            v-model="selectedInsurer"
            @input="filterInsurerList"
            @update:modelValue="handleInsurerSelect"
          ></v-combobox>
        </v-list-item>
      </v-list>
      <v-list style="margin-top: 24px">
        <v-list-item
          ><b>【業種】</b>
          <v-divider
            :thickness="3"
            class="border-opacity-75"
            style="margin-top: 12px"
            color="#009688"
          ></v-divider>
        </v-list-item>
        <v-list-item
          v-for="(industry, index) in industries"
          :key="index"
          @click="handleListItemClick(industry.name, index + 1)"
        >
          <v-list-item-content
            class="d-flex justify-between"
            style="margin-top: 16px"
          >
            <span>{{ industry.name }}</span>
            <span v-if="industry.count !== null" style="margin-left: auto"
              >({{ industry.count }}社)</span
            >
          </v-list-item-content>
          <v-divider
            :thickness="3"
            class="border-opacity-75"
            color="#009688"
          ></v-divider>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap");
.v-application {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
}
h2 {
  color: #505050;
  padding: 0.5em;
  display: inline-block;
  line-height: 1.2;
  background: #e0f2f1;
  vertical-align: middle;
  border-radius: 20px 0px 0px 20px; /*左側の角を丸く*/
  width: 100%;
  margin: 15px 0;
}
th {
  background: #f5f5f5;
}
.v-card-subtitle {
  color: #00796b;
}
.v-card {
  margin: 15px 0;
  width: 100%;
}
.industry-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.align-left {
  margin-right: auto;
  display: flex;
}
</style>

<script>
import axios from "axios";
export default {
  data() {
    return {
      nav_lists: [
        {
          name: "ACTION!健康経営",
          href: "https://kenko-keiei.jp/",
          prependAvatarUrl:
            "https://th.bing.com/th?id=ODLS.79b77d17-8ed2-4031-aa08-ef6822828d4d&w=32&h=32&qlt=90&pcl=fffffa&o=6&pid=1.2",
        },
        {
          name: "レビューを書く",
          href: "https://forms.office.com/r/6MVydffiis",
          prependAvatarUrl:
            "https://menter.jp/blog/wp-content/uploads/2023/02/forms1.webp",
        },
      ],
      reloadflag: true,
      industries: [
        { name: "水産・農林業", count: 3 },
        { name: "鉱業", count: 2 },
        { name: "建設業", count: 79 },
        { name: "食料品", count: 50 },
        { name: "繊維製品", count: 12 },
        { name: "パルプ・紙", count: 10 },
        { name: "化学", count: 76 },
        { name: "医薬品", count: 33 },
        { name: "石油・石炭製品", count: 3 },
        { name: "ゴム製品", count: 12 },
        { name: "ガラス・土石製品", count: 15 },
        { name: "鉄鋼", count: 16 },
        { name: "非鉄金属", count: 14 },
        { name: "金属製品", count: 29 },
        { name: "機械", count: 53 },
        { name: "電気機器", count: 119 },
        { name: "輸送用機器", count: 112 },
        { name: "精密機器", count: 25 },
        { name: "その他製品", count: 36 },
        { name: "電気・ガス業", count: 19 },
        { name: "陸運業", count: 42 },
        { name: "海運業", count: 3 },
        { name: "空運業", count: 16 },
        { name: "倉庫・運輸関連業", count: 14 },
        { name: "情報・通信業", count: 272 },
        { name: "卸売業", count: 188 },
        { name: "小売業", count: 275 },
        { name: "銀行業", count: 70 },
        { name: "証券、商品先物取引業", count: 6 },
        { name: "保険業", count: 38 },
        { name: "その他金融業", count: 26 },
        { name: "不動産業", count: 53 },
        { name: "サービス業", count: 307 },
        { name: "医療法人、社会福祉法人、健保組合", count: 111 },
        { name: "社団・財団法人、商工会議所・商工会", count: 36 },
        { name: "士業法人、NPO法人、公・特殊法人、その他法人", count: 63 },
      ],
      insurer_list: [],
      selectedInsurer: null,
      filteredInsurerList: [],
    };
  },
  watch: {
    $route(routeInstance) {
      this.createTitleDesc(routeInstance);
    },
  },
  methods: {
    handleClick() {
      this.$router.push({
        name: "homepage",
        params: { industryName: "全業種" },
      });
    },
    handleListItemClick(industry, industryCode) {
      // パラメータとして業種名を渡してHomePage.vueに遷移
      this.$router.push({
        name: "homepage",
        params: { industryName: industry, industryCode: industryCode },
      });
    },
    createTitleDesc: function (routeInstance) {
      //titleを設定する処理
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title + " | WellCom";
        document.title = setTitle;
      } else {
        document.title = "WellCom -健康経営フィードバックシートポータルサイト-";
      }
    },
    getInsurerList() {
      // 保険組合リストを取得
      axios
        .get("https://welcomeapi.armdxp.com/INSURER_LIST")
        .then((response) => {
          this.insurer_list = response.data.insurer_list;
          this.filteredInsurerList = this.insurer_list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterInsurerList(query) {
      if (typeof query === "string" && query) {
        this.filteredInsurerList = this.insurer_list.filter((insurer) =>
          insurer.toLowerCase().includes(query.toLowerCase())
        );
      } else {
        this.filteredInsurerList = this.insurer_list;
      }
    },
    handleInsurerSelect() {
      const selected = this.filteredInsurerList.find(
        (item) => item === this.selectedInsurer
      );
      if (this.selectedInsurer && selected) {
        this.$router.push({
          name: "homepage",
          params: { insurerName: this.selectedInsurer },
        });
        this.selectedInsurer = null;
      }
    },
  },
  mounted: function () {
    var routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
    this.getInsurerList();
  },
};
</script>
