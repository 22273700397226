import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'

// Google Analyticsの設定
function gtag() {
  window.dataLayer.push(arguments);
}

router.afterEach((to) => {
  gtag('config', 'G-WHDQ5CB8KD', {
    page_path: to.fullPath,
  });
});

loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .mount('#app')
