<template>
  <div class="mb-10">
    <div class="title my-5">
      <v-row align="center" no-gutters style="height: 100px">
        <v-col cols="8">
          <v-sheet class="pa-2 ma-2">
            <h1>{{ title }}</h1>
          </v-sheet>
        </v-col>
        <v-col cols="4">
          <v-sheet class="pa-2 ma-2">
            <v-form ref="searchForm" @submit.prevent="searchCompaniesByText">
              <v-text-field
                v-model="searchText"
                :placeholder="searchPlaceholder"
                append-icon="mdi-magnify"
                @click:append="searchCompaniesByText"
                solo
              >
              </v-text-field>
            </v-form>
            <!-- <v-form ref="form">
							<v-select
								v-model="select"
								:items="items"
								:rules="[v => !!v || 'Item is required']"
								label="並び替え"
							></v-select>
						</v-form> -->
          </v-sheet>
        </v-col>
      </v-row>
      <v-divider
        :thickness="3"
        class="border-opacity-75"
        color="#009688"
      ></v-divider>
      <!-- ページコントロール -->
      <div class="ma-5 text-center">
        <button @click="prevPage" :disabled="currentPage <= 1">
          <v-chip color="teal cursor-pointer">前のページ</v-chip>
        </button>
        <span class="mx-3"
          >{{ currentPage }}ページ / {{ totalPage }}ページ</span
        >
        <button @click="nextPage" :disabled="currentPage >= totalPages">
          <v-chip color="teal cursor-pointer">次のページ</v-chip>
        </button>
      </div>
    </div>
    <div class="companyList">
      <v-row align="center" justify="start">
        <template v-if="!isLoadingError">
          <!-- 各画面サイズに応じたカラム幅の設定 -->
          <v-col
            v-for="company in paginatedCompanies"
            :key="company.code"
            cols="12"
            sm="12"
            md="6"
            xl="4"
          >
            <v-list-item
              style="margin: 10px"
              @click="handleCompanyClick(company)"
            >
              <v-card
                class="mx-auto"
                max-width="100%"
                :color="color"
                :company="company"
              >
                <v-card-item>
                  <div>
                    <div class="text-h6 mb-1">
                      <b>{{ company.corporate_name }}</b>
                    </div>
                    <p>
                      {{ company.insurer_name }}
                    </p>
                    <div class="my-3">
                      <v-chip label class="mr-1">{{
                        company.industry_name
                      }}</v-chip>
                      <v-chip
                        label
                        variant="outlined"
                        color="red-darken-4"
                        class="mr-1"
                        v-if="company.list_flag"
                        >上場</v-chip
                      >
                      <v-chip
                        label
                        variant="outlined"
                        color="lime-darken-3"
                        class="mr-1"
                        v-if="company.brand_flag"
                        >健康経営銘柄</v-chip
                      >
                      <v-chip
                        label
                        variant="outlined"
                        color="primary"
                        class="mr-1"
                        v-if="company.white500_flag"
                        >ホワイト500</v-chip
                      >
                      <v-chip
                        label
                        variant="outlined"
                        color="primary"
                        class="mr-1"
                        v-if="company.big_good_flag"
                        >優良法人（大）</v-chip
                      >
                      <v-chip
                        label
                        variant="outlined"
                        color="primary"
                        class="mr-1"
                        v-if="company.bright500_flag"
                        >ブライト500</v-chip
                      >
                      <v-chip
                        label
                        variant="outlined"
                        color="primary"
                        class="mr-1"
                        v-if="company.small_good_flag"
                        >優良法人（中小）</v-chip
                      >
                    </div>
                    <p v-if="company.submit_sheet_flag">
                      【フィードバックシート公開中】
                    </p>
                    <p v-else>【フィードバックシート非公開】</p>
                    <div v-if="company.submit_sheet_flag" class="text-caption">
                      総合順位：{{ company.overall_rank }}/ 3169社中
                    </div>
                    <div v-if="company.submit_sheet_flag" class="text-caption">
                      総合評価：{{ company.overall_deviation_value }}
                    </div>
                  </div>
                </v-card-item>
              </v-card>
            </v-list-item>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12">
            <v-card>
              <v-card-title
                >企業データの取得に失敗しました。VPN接続できているかご確認ください。</v-card-title
              >
            </v-card>
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["industryName", "industryCode", "insurerName"], // ルーターから業種名を受け取る
  data: () => ({
    title: "",
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    allCompanies: [],
    currentPage: 1, // 現在のページ番号
    itemsPerPage: 9, // 1ページあたりのアイテム数
    searchText: "",
    searchPlaceholder: "企業名",
    totalPage: 0,
    isLoadingError: false,
  }),
  computed: {
    totalPages() {
      // 総ページ数を計算
      this.updateTotalPage();
      return this.totalPage;
    },
    paginatedCompanies() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.allCompanies.slice(start, end);
    },
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();

      if (valid) alert("Form is valid");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    searchCompaniesByText() {
      this.currentPage = 1;
      if (this.searchText.length != 0) {
        const encodedSearchText = encodeURIComponent(this.searchText);
        axios
          .get(
            `https://welcomeapi.armdxp.com/RESEARCH?researchWord=${encodedSearchText}`
          )

          .then((response) => {
            this.allCompanies = response.data;
            this.title = "検索結果";
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          });
      } else {
        this.fetchData();
      }
    },
    searchCompaniesByIndustry(industryName, industryCode) {
      axios
        .get(`https://welcomeapi.armdxp.com/RESEARCH?industry=${industryCode}`)

        .then((response) => {
          this.allCompanies = response.data;
          this.title = industryName;
          this.currentPage = 1;
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
        });
    },
    searchCompaniesByInsurer(insurerName) {
      axios
        .get(
          `https://welcomeapi.armdxp.com/RESEARCH?insurerName=${insurerName}`
        )

        .then((response) => {
          this.allCompanies = response.data;
          this.title = insurerName;
          this.currentPage = 1;
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
        });
    },
    fetchData() {
      axios
        .get("https://welcomeapi.armdxp.com/INIT")
        .then((response) => {
          this.allCompanies = response.data;
          this.title = "全企業一覧";
          this.currentPage = 1;
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
          this.allCompanies = [];
          this.isLoadingError = true;
        });
    },
    handleCompanyClick(company) {
      console.log(company.code);
      if (company.code != 0) {
        this.$router.push({ name: "company", params: { code: company.code } });
      } else {
        console.log("この企業はクリックできません");
      }
    },
    updateTotalPage() {
      this.totalPage = Math.ceil(this.allCompanies.length / this.itemsPerPage);
    },
  },
  mounted() {
    if (this.insurerName !== null && this.insurerName !== undefined) {
      this.searchCompaniesByInsurer(this.insurerName);
    } else if (
      this.industryName !== "全業種" &&
      this.industryName !== null &&
      this.industryName !== undefined
    ) {
      this.searchCompaniesByIndustry(this.industryName, this.industryCode);
    } else {
      this.fetchData();
    }
  },
  watch: {
    industryName(newIndustryName) {
      if (newIndustryName == "全業種") {
        this.fetchData();
      } else {
        this.searchCompaniesByIndustry(newIndustryName, this.industryCode); // 業種名が変更されたらタイトルを更新
      }
    },
    insurerName(newInsurerName) {
      this.searchCompaniesByInsurer(newInsurerName);
    },
  },
};
</script>
