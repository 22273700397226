<template>
	<v-row>
		<v-col cols="12">
			<h2>⑦ 経営レベルの会議での議題化</h2>
		</v-col>
		<v-col cols="8">
			<h3>■ 全社における健康経営の推進に関して、経営レベルの会議（取締役会や経営会議等）で議題にしている内容</h3>
				<v-table>
					<thead>
						<tr>
							<th class="text-left" width="10%">番号</th>
							<th class="text-left" width="10%">実施</th>
							<th class="text-left" width="80%">議題にしている内容</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="item in desserts4"
							:key="item.index"
						>
							<td><b>{{ item.index }}</b></td>
							<td>{{ item.apply }}</td>
							<td>{{ item.body }}</td>
						</tr>
					</tbody>
				</v-table>
			<h3>■ 2022年度の会議⾃体の総実施回数と健康経営の推進を議題にした回数</h3>
				<v-table>
					<thead>
						<tr>
							<th class="text-left" width="30%"></th>
							<th class="text-left" width="30%">①総実施回数</th>
							<th class="text-left" width="30%">②健康経営を議題にした回数</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="item in desserts5"
							:key="item.name"
						>
							<td><b>{{ item.name }}</b></td>
							<td>{{ item.num1 }} 回</td>
							<td>{{ item.num2 }} 回</td>
						</tr>
					</tbody>
				</v-table>
		</v-col>
	</v-row><br><br>
	<v-row>
		<v-col cols="12">
			<h2>⑧ 各種施策の2022年度の参加率</h2>
		</v-col>
		<v-col cols="8">
				<template
							v-for="item in desserts6"
							:key="item.name"
				>
					<h3>■ {{ item.category }}</h3>
					<v-table>
						<tbody>
							<tr v-if="item.type === 2">
								<th class="text-left" width="20%">取組概要</th>
								<th class="text-left" width="30%" colspan="3">{{ item.summary }}</th>
							</tr>
							<tr v-if="item.type === 2">
								<th class="text-left" width="20%">取組内容</th>
								<th class="text-left" width="30%" colspan="3">{{ item.body }}</th>
							</tr>
							<tr v-if="item.type !== 3">
								<th class="text-left" width="20%">(a)対象者の比率<br>※全従業員に占める対象者の割合</th>
								<th class="text-left" width="30%">{{ item.aRate }} ％</th>
								<th class="text-left" width="20%">(b)参加者の比率<br>※対象者に占める参加者の割合</th>
								<th class="text-left" width="30%">{{ item.bRate }} ％</th>
							</tr>
						</tbody>
					</v-table>
					<v-table v-if="item.type === 3">
						<tbody>
							<tr>
								<th class="text-left" width="10%"></th>
								<th class="text-left" width="10%">実施</th>
								<th class="text-left" width="20%" colspan="2">参加率</th>
							</tr>
							<tr>
								<td class="text-left">女性限定</td>
								<td class="text-left">{{ item.womenFlgA }}</td>
								<td class="text-left">全女性従業員の</td>
								<td class="text-left">{{ item.aRate }} ％</td>
							</tr>
							<tr>
								<td class="text-left">管理職限定</td>
								<td class="text-left">{{ item.womenFlgB }}</td>
								<td class="text-left">全管理職の</td>
								<td class="text-left">{{ item.bRate }} ％</td>
							</tr>
							<tr>
								<td class="text-left">限定しない</td>
								<td class="text-left">{{ item.womenFlgC }}</td>
								<td class="text-left">全従業員の</td>
								<td class="text-left">{{ item.cRate }} ％</td>
							</tr>
						</tbody>
					</v-table>
				</template>
		</v-col>
	</v-row><br><br>
</template>
<script>
	// import CompanyInfo from "@/views/CompanyInfo.vue";
import axios from "axios";

		export default {
			props: ["companyInfo"],
			data: () => ({
				tab: null,

				desserts4: [
					{
						index: 1,
						apply: "",
						body: "健康経営の推進に関する体制"
					},
					{
						index: 2,
						apply: "〇",
						body: "従業員の健康課題の分析結果"
					},
					{
						index: 3,
						apply: "〇",
						body: "健康経営の具体的な取り組みと期待する効果のつながりの整理（戦略マップなど）"
					},
					{
						index: 4,
						apply: "〇",
						body: "従業員の健康状態や⽣活習慣に対する健康経営の取り組みの効果"
					},
					{
						index: 5,
						apply: "〇",
						body: "従業員の業務パフォーマンスや経営上の課題に対する健康経営の取り組みの効果"
					},
					{
						index: 6,
						apply: "〇",
						body: "健康経営の取り組みに関する外部からの評価（各種認定取得、IRや採⽤⾯での効果など）"
					},
					{
						index: 7,
						apply: "〇",
						body: "健康経営の取り組みに関する対外的な発信内容（投資家との対話、求⼈でのPRなど）"
					},
					{
						index: 8,
						apply: "",
						body: "その他"
					},
				],
				desserts5: [
					{
						name: "(a)取締役会",
						num1: "",
						num2: ""
					},
					{
						name: "(b)経営レベルの会議（取締役会以外）",
						num1: "",
						num2: ""
					},
				],
				desserts6: [
					{
						type: 1,
						category: "健康保持・増進に関する教育",
						summary: "",
						body: "",
						aRate: "",
						bRate: "",
						cRate: "",
						womenFlgA: "",
						womenFlgB: "",
						womenFlgC: "",
					},
					{
						type: 2,
						category: "コミュニケーション促進",
						summary: "",
						body: "",
						aRate: "",
						bRate: "",
						cRate: "",
						womenFlgA: "",
						womenFlgB: "",
						womenFlgC: "",
					},
					{
						type: 2,
						category: "食生活改善",
						summary: "⾷⽣活改善に向けたアプリ提供、カロリー記録等のサポートを実施している",
						body: "⽣活習慣改善アプリ「カロミル」を利⽤して、社員の⾷⽣活のサポートをしている",
						aRate: "",
						bRate: "",
						cRate: "",
						womenFlgA: "",
						womenFlgB: "",
						womenFlgC: "",
					},
					{
						type: 2,
						category: "運動習慣定着",
						summary: "運動奨励活動（歩数計の配布、歩⾏や階段使⽤の奨励、表彰等）や、運動促進のためのツールの提供（アプリ、動画配信等）を⾏っている",
						body: "個⼈、チームの期間中の平均歩数をカウントし、⽬標歩数を達成できるか競うウォーキングイベントを開催",
						aRate: "",
						bRate: "",
						cRate: "",
						womenFlgA: "",
						womenFlgB: "",
						womenFlgC: "",
					},
					{
						type: 3,
						category: "⼥性特有の健康関連課題に関する知識を得るための取り組み（セミナー等での教育）",
						summary: "",
						body: "",
						aRate: "",
						bRate: "",
						cRate: "",
						womenFlgA: "〇",
						womenFlgB: "〇",
						womenFlgC: "〇",
					},
				],
			}),
			methods: {
				getHealthAgendaSymbol(value) {
				return value ? "〇" : "×";
			},},
			mounted() {
				axios
					.get('https://welcomeapi.armdxp.com/DETAIL_3?code=' + this.companyInfo.code)
					.then((response) => {
						let health_management_agenda_1 = response.data.health_management_agenda_1;
						this.desserts4[0].apply = this.getHealthAgendaSymbol(health_management_agenda_1);
						let health_management_agenda_2 = response.data.health_management_agenda_2
						this.desserts4[1].apply = this.getHealthAgendaSymbol(health_management_agenda_2);
						let health_management_agenda_3 = response.data.health_management_agenda_3
						this.desserts4[2].apply = this.getHealthAgendaSymbol(health_management_agenda_3);
						let health_management_agenda_4 = response.data.health_management_agenda_4
						this.desserts4[3].apply = this.getHealthAgendaSymbol(health_management_agenda_4);
						let health_management_agenda_5 = response.data.health_management_agenda_5
						this.desserts4[4].apply = this.getHealthAgendaSymbol(health_management_agenda_5);
						let health_management_agenda_6 = response.data.health_management_agenda_6
						this.desserts4[5].apply = this.getHealthAgendaSymbol(health_management_agenda_6);
						let health_management_agenda_7 = response.data.health_management_agenda_7
						this.desserts4[6].apply = this.getHealthAgendaSymbol(health_management_agenda_7);
						let health_management_agenda_8 = response.data.health_management_agenda_8
						this.desserts4[7].apply = this.getHealthAgendaSymbol(health_management_agenda_8);
						this.desserts5[0].num1 = response.data.agenda_count_a_1
						this.desserts5[0].num2 = response.data.agenda_count_a_2
						this.desserts5[1].num1 = response.data.agenda_count_b_1
						this.desserts5[1].num2 = response.data.agenda_count_b_2
						this.desserts6[0].aRate = response.data.health_education_promotion_a
						this.desserts6[0].bRate = response.data.health_education_promotion_b
						this.desserts6[1].summary = response.data.communication_promotion_choice_content
						this.desserts6[1].body = response.data.communication_promotion_approach_content
						this.desserts6[1].aRate = response.data.communication_promotion_a
						this.desserts6[1].bRate = response.data.communication_promotion_b
						this.desserts6[2].summary = response.data.diet_improvement_choice_content
						this.desserts6[2].body = response.data.diet_improvement_approach_content
						this.desserts6[2].aRate = response.data.diet_improvement_a
						this.desserts6[2].bRate = response.data.diet_improvement_b
						this.desserts6[3].summary = response.data.exercise_habit_formation_choice_content
						this.desserts6[3].body = response.data.exercise_habit_formation_approach_content
						this.desserts6[3].aRate = response.data.exercise_habit_formation_a
						this.desserts6[3].bRate = response.data.exercise_habit_formation_b
						let womenFlgA = response.data.women_health_education_a_1
						this.desserts6[4].womenFlgA =this.getHealthAgendaSymbol(womenFlgA);
						let womenFlgB = response.data.women_health_education_a_2
						this.desserts6[4].womenFlgB =this.getHealthAgendaSymbol(womenFlgB);
						let womenFlgC = response.data.women_health_education_a_3
						this.desserts6[4].womenFlgC =this.getHealthAgendaSymbol(womenFlgC);
						this.desserts6[4].aRate = response.data.women_health_education_b_1
						this.desserts6[4].bRate = response.data.women_health_education_b_2
						this.desserts6[4].cRate = response.data.women_health_education_b_3

					})
			}
		}
	</script>