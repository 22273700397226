<template>
	<v-row>
		<v-col>
			<h2>⑨ 具体的な健康課題への対応</h2>
			<h3>■ 健康経営度調査における健康課題の分類と対応度</h3>
			<v-table>
			<thead>
				<tr>
					<th class="text-left"></th>
					<th class="text-left"></th>
					<th class="text-left" colspan="2">課題への対応度</th>
					<th class="text-left"></th>
				</tr>
				<tr>
					<th class="text-left" width="10%">番号</th>
					<th class="text-left" width="30%">課題分類</th>
					<th class="text-left" width="10%">貴社</th>
					<th class="text-left" width="10%">業種平均</th>
					<th class="text-left" width="40%">対応する設問</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in desserts9" :key="item.index">
					<td><b>{{ item.index }}</b></td>
					<td>{{ item.category }}</td>
					<td>{{ item.rank }}</td>
					<td>{{ item.average }}</td>
				<td>
					<a href="#" class="question" @click.prevent="showQuestion(item)">対応Q番号</a>
				</td>
				</tr>
			</tbody>
			</v-table>
			<v-row>
				<v-col cols="6">
					<h3>■ 重点を置いている具体的な施策とその効果</h3>
					<v-card>
						<v-card-subtitle>課題のテーマ</v-card-subtitle>
						<v-card-title>{{ desserts9_2[0].title }}</v-card-title>
						<v-card-subtitle>課題内容</v-card-subtitle>
						<v-card-text>{{ desserts9_2[0].resolution_content }}</v-card-text>
						<v-card-subtitle>施策実施結果</v-card-subtitle>
						<v-card-text>{{ desserts9_2[0].implementation }}</v-card-text>
						<v-card-subtitle>効果検証結果</v-card-subtitle>
						<v-card-text>{{ desserts9_2[0].effect_verification }}</v-card-text>
					</v-card>
					<h3>同じ課題のテーマを選んだ企業の回答例</h3>
					<v-card variant="text" style="margin: 0px !important;">
						<v-card color="grey-lighten-4" v-for = "item_2 in desserts9_2_0_reference" :key = "item_2.title">
							<v-card-title>{{item_2.corporate_name}}  偏差値：{{item_2.score }}  業種：{{item_2.industry}}</v-card-title>
							<v-card-subtitle>課題内容</v-card-subtitle>
							<v-card-text>{{ item_2.resolution_content }}</v-card-text>
							<v-card-subtitle>施策実施結果</v-card-subtitle>
							<v-card-text>{{ item_2.implementation }}</v-card-text>
							<v-card-subtitle>効果検証結果</v-card-subtitle>
							<v-card-text>{{ item_2.effect_verification }}</v-card-text>
						</v-card>
					</v-card>
				</v-col>
				<v-col cols="6">
					<br>
					<v-card>
						<v-card-subtitle>課題のテーマ</v-card-subtitle>
						<v-card-title>{{ desserts9_2[1].title }}</v-card-title>
						<v-card-subtitle>課題内容</v-card-subtitle>
						<v-card-text>{{ desserts9_2[1].resolution_content }}</v-card-text>
						<v-card-subtitle>施策実施結果</v-card-subtitle>
						<v-card-text>{{ desserts9_2[1].implementation }}</v-card-text>
						<v-card-subtitle>効果検証結果</v-card-subtitle>
						<v-card-text>{{ desserts9_2[1].effect_verification }}</v-card-text>
					</v-card>
					<h3>同じ課題のテーマを選んだ企業の回答例</h3>
					<v-card variant="text" style="margin: 0px !important;">
						<v-card color="grey-lighten-4" v-for = "item_2 in desserts9_2_1_reference" :key = "item_2.title">
							<v-card-title>{{item_2.corporate_name}}  偏差値：{{item_2.score}}  業種：{{item_2.industry}}</v-card-title>
							<v-card-subtitle>課題内容</v-card-subtitle>
							<v-card-text>{{ item_2.resolution_content }}</v-card-text>
							<v-card-subtitle>施策実施結果</v-card-subtitle>
							<v-card-text>{{ item_2.implementation }}</v-card-text>
							<v-card-subtitle>効果検証結果</v-card-subtitle>
							<v-card-text>{{ item_2.effect_verification }}</v-card-text>
						</v-card>
					</v-card>
				</v-col>
			</v-row>
		</v-col>

		<v-dialog v-model="dialog" max-width="1000">
			<!-- ポップアップの内容 -->
			<v-card>
				<v-toolbar color="blue-grey">
					<v-toolbar-title>{{ this.modalTitle }}</v-toolbar-title>
				</v-toolbar>
					<div>
						<v-tabs
							v-model="questionTab"
						>
							<v-tab
								v-for="(tabQuestion, index) in selectedQuestion.list"
								:key="tabQuestion.name"
								@click="changeQuestionImg(this.selectedQuestion.list[index])"
							>
								{{ this.selectedQuestion.list[index] }}
							</v-tab>
						</v-tabs>
					</div>
				<v-tabs-items v-model="questionTab">
					<v-tabs-items
						:value="this.selectedQuestion.text"
					>
						<v-card-text>
							<b>{{ this.questionDetail }}</b>
						</v-card-text>
						<img :src="this.imageSrc" style="width: 100%;" alt="Question Image"/>
					</v-tabs-items>
				</v-tabs-items>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
	import axios from "axios";
	// question_details.jsをインポート
	import questionDetails from "../../../data/question_details.js";
	export default {
		props: ["companyInfo"],
		data: () => ({
			tab: null,
			desserts9: [
				{
					index: 1,
					category: "健康状態にかかわらず全従業員に対する疾病の発⽣予防",
					rank:"",
					average: "",
					questions:
						"Q38、Q39、Q42、Q43、Q44、Q54、Q55、Q66(a)、Q66(c)",
				},
				{
					index: 2,
					category: "⽣活習慣病などの疾病の⾼リスク者に対する重症化予防",
					rank:"",
					average: "",
					questions: "Q40、Q50、Q51、Q52、Q53、Q66(f)〜(h)、Q67",
				},
				{
					index: 3,
					category: "メンタルヘルス不調等のストレス関連疾患の発⽣予防・早期発⾒・対応",
					rank:"",
					average: "",
					questions: "Q41、Q60、Q68",
				},
				{
					index: 4,
					category: "従業員の⽣産性低下防⽌・事故発⽣予防",
					rank:"",
					average: "",
					questions: "Q56、Q66(d)、Q66(e)",
				},
				{
					index: 5,
					category: '⼥性特有の健康関連課題への対応、⼥性の健康保持・増進',
					rank:"",
					average: "",
					questions: "Q57、Q58",
				},
				{
					index: 6,
					category: '休職後の職場復帰、就業と治療の両⽴',
					rank:"",
					average: "",
					questions: "Q49、Q71",
				},
				{
					index: 7,
					category: '労働時間の適正化、ワークライフバランス・⽣活時間の確保',
					rank:"",
					average: "",
					questions: "Q45、Q46、Q59、Q69、Q70",
				},
				{
					index: 8,
					category: '従業員間のコミュニケーションの促進',
					rank:"",
					average: "",
					questions: "Q48、Q72",
				},
				{
					index: 9,
					category: '従業員の感染症予防（インフルエンザなど）',
					rank:"",
					average: "",
					questions: "Q63",
				},
				{
					index: 10,
					category: '従業員の喫煙率低下',
					rank:"",
					average: "",
					questions: "Q64、Q65、Q66(b)",
				}
			],
			dialog: false,
			selectedQuestion: {
				text: '',
			},
			desserts9_2: [
				{
					title: "",
					resolution_content:
						"",
					implementation:
						"",
					effect_verification:
						"",
},
				{
					title: '',
					resolution_content: '',
					implementation: '',
					effect_verification: '',
				},
			],
			desserts9_2_0_reference: [
				{
					corporate_name:"",
					code:"",
					industry:"",
					score:"",
					resolution_content: "",
					implementation: "",
					effect_verification:"",
				},
				{
					corporate_name:"",
					code:"",
					industry:"",
					score:"",
					resolution_content: "",
					implementation: "",
					effect_verification:"",
				},
				{
					corporate_name:"",
					code:"",
					industry:"",
					score:"",
					resolution_content: "",
					implementation: "",
					effect_verification:"",
				},
				{
					corporate_name:"",
					code:"",
					industry:"",
					score:"",
					resolution_content: "",
					implementation: "",
					effect_verification:"",
				},
				{
					corporate_name:"",
					code:"",
					industry:"",
					score:"",
					resolution_content: "",
					implementation: "",
					effect_verification:"",
				}
			],
			desserts9_2_1_reference: [
				{
					corporate_name:"",
					code:"",
					industry:"",
					score:"",
					resolution_content: "",
					implementation: "",
					effect_verification:"",
				},
				{
					corporate_name:"",
					code:"",
					industry:"",
					score:"",
					resolution_content: "",
					implementation: "",
					effect_verification:"",
				},
				{
					corporate_name:"",
					code:"",
					industry:"",
					score:"",
					resolution_content: "",
					implementation: "",
					effect_verification:"",
				},
				{
					corporate_name:"",
					code:"",
					industry:"",
					score:"",
					resolution_content: "",
					implementation: "",
					effect_verification:"",
				},
				{
					corporate_name:"",
					code:"",
					industry:"",
					score:"",
					resolution_content: "",
					implementation: "",
					effect_verification:"",
				}
			],
			questionTab: null,
			imageSrc: "",
			questionDetail: "",
			modalTitle: "",
			}),
			mounted() {
				axios
				.get("https://welcomeapi.armdxp.com/DETAIL_4?code=" + this.companyInfo.code)
				.then((response) => {
					this.desserts9[0].rank = response.data.health_issue1
					this.desserts9[0].average = response.data.industry_health_issue1
					this.desserts9[1].rank = response.data.health_issue2
					this.desserts9[1].average = response.data.industry_health_issue2
					this.desserts9[2].rank = response.data.health_issue3
					this.desserts9[2].average = response.data.industry_health_issue3
					this.desserts9[3].rank = response.data.health_issue4
					this.desserts9[3].average = response.data.industry_health_issue4
					this.desserts9[4].rank = response.data.health_issue5
					this.desserts9[4].average = response.data.industry_health_issue5
					this.desserts9[5].rank = response.data.health_issue6
					this.desserts9[5].average = response.data.industry_health_issue6
					this.desserts9[6].rank = response.data.health_issue7
					this.desserts9[6].average = response.data.industry_health_issue7
					this.desserts9[7].rank = response.data.health_issue8
					this.desserts9[7].average = response.data.industry_health_issue8
					this.desserts9[8].rank = response.data.health_issue9
					this.desserts9[8].average = response.data.industry_health_issue9
					this.desserts9[9].rank = response.data.health_issue10
					this.desserts9[9].average = response.data.industry_health_issue10
					this.desserts9_2[0].title = response.data.health_issue1_resolution_theme_content
					this.desserts9_2[0].resolution_content = response.data.health_issue1_resolution_content
					this.desserts9_2[0].implementation = response.data.health_issue1_implementation
					this.desserts9_2[0].effect_verification = response.data.health_issue1_effect_verification
					this.desserts9_2[1].title = response.data.health_issue2_resolution_theme_content
					this.desserts9_2[1].resolution_content = response.data.health_issue2_resolution_content
					this.desserts9_2[1].implementation = response.data.health_issue2_implementation
					this.desserts9_2[1].effect_verification = response.data.health_issue2_effect_verification
					let health_issue1_lists = response.data.health_issue1_ls
					let num_0 = 0
					for (let health_issue1_ls of health_issue1_lists) {
						this.desserts9_2_0_reference[num_0].corporate_name = health_issue1_ls[0];
						this.desserts9_2_0_reference[num_0].code = health_issue1_ls[1];
						this.desserts9_2_0_reference[num_0].industry = health_issue1_ls[2];
						this.desserts9_2_0_reference[num_0].score = health_issue1_ls[3];
						this.desserts9_2_0_reference[num_0].resolution_content = health_issue1_ls[4];
						this.desserts9_2_0_reference[num_0].implementation = health_issue1_ls[5];
						this.desserts9_2_0_reference[num_0].effect_verification = health_issue1_ls[6];
						num_0 += 1
					}
					let health_issue2_lists = response.data.health_issue2_ls
					let num_1 = 0
					for (let health_issue2_ls of health_issue2_lists) {
						this.desserts9_2_1_reference[num_1].corporate_name = health_issue2_ls[0];
						this.desserts9_2_1_reference[num_1].code = health_issue2_ls[1];
						this.desserts9_2_1_reference[num_1].industry = health_issue2_ls[2];
						this.desserts9_2_1_reference[num_1].score = health_issue2_ls[3];
						this.desserts9_2_1_reference[num_1].resolution_content = health_issue2_ls[4];
						this.desserts9_2_1_reference[num_1].implementation = health_issue2_ls[5];
						this.desserts9_2_1_reference[num_1].effect_verification = health_issue2_ls[6];
						num_1 += 1
					}

					});
				},
			methods: {
				showQuestion(item) {
					this.selectedQuestion.list = item.questions.split('、');
					// this.selectedQuestion.listには各Qがリスト形式で格納されている
					this.selectedQuestion.text = this.selectedQuestion.list[0];
					this.questionTab = this.selectedQuestion.text;
					this.dialog = true;
					this.modalTitle = "【"+item.category+"】に対応した調査票の設問";
					this.changeQuestionImg(this.questionTab);
				},
				// 選択された質問に対する設問内容を取得するメソッド
				getQuestionDetails(question) {
					let filterd_question = question.substr(0,3)
					this.questionDetail = questionDetails.myMap.get(filterd_question) || '該当する設問がありません。';
				},
				changeQuestionImg(question) {
					let changedQuestionLowarCase = question.toLowerCase();
					this.imageSrc = require(`@/assets/question_img/${changedQuestionLowarCase.substring(0,3)}.png`);
					this.getQuestionDetails(question);
				},
			},
	};
</script>

<style scoped>
.question {
    display: inline-block;
	text-decoration: none;
	color: #009688;
}
</style>
