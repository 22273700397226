<template>
	<v-row>
		<v-col cols="12">
			<h2>⑤ 健康経営の戦略</h2>
		</v-col>
		<v-row>
			<v-col cols="6">
				<h3>■ 経営上の課題と期待する効果①</h3>
				<v-card v-if="desserts5[0].choices_content !== null">
					<v-card-subtitle>健康経営で解決した経営上の課題</v-card-subtitle>
					<v-card-title>{{ desserts5[0].choices_content }}</v-card-title>
					<v-card-text>
						{{ desserts5[0].issue_content }}
					</v-card-text>
					<v-card-subtitle>健康経営の実施により期待する効果</v-card-subtitle>
					<v-card-text>{{ desserts5[0].content_effect }}</v-card-text>
				</v-card>
				<h3>同じ課題のテーマを選んだ企業の回答例</h3>
				<v-card variant="text" style="margin: 0px !important;" v-if="desserts5[0].choices_content !== null">
					<v-card color="grey-lighten-4" v-for = "item_2 in desserts5_1_reference" :key = "item_2.corporate_name">
						<v-card-title>{{item_2.corporate_name}}  偏差値：{{item_2.score }}  業種：{{item_2.industry}}</v-card-title>
						<v-card-subtitle>健康経営で解決した経営上の課題</v-card-subtitle>
						<v-card-text>{{ item_2.issue_content }}</v-card-text>
						<v-card-subtitle>健康経営の実施により期待する効果</v-card-subtitle>
						<v-card-text>{{ item_2.content_effect }}</v-card-text>
					</v-card>
				</v-card>
			</v-col>
			<v-col cols="6">
				<h3>■ 経営上の課題と期待する効果②</h3>
				<v-card  v-if="desserts5[1].choices_content !== null">
					<v-card-subtitle>健康経営で解決した経営上の課題</v-card-subtitle>
					<v-card-title>{{ desserts5[1].choices_content }}</v-card-title>
					<v-card-text>
						{{ desserts5[1].issue_content }}
					</v-card-text>
					<v-card-subtitle>健康経営の実施により期待する効果</v-card-subtitle>
					<v-card-text>{{ desserts5[1].content_effect }}</v-card-text>
				</v-card>
				<h3>同じ課題のテーマを選んだ企業の回答例</h3>
				<v-card variant="text" style="margin: 0px !important;" v-if="desserts5[1].choices_content !== null">
					<v-card color="grey-lighten-4" v-for = "item_2 in desserts5_2_reference" :key = "item_2.corporate_name">
						<v-card-title>{{item_2.corporate_name}}  偏差値：{{item_2.score }}  業種：{{item_2.industry}}</v-card-title>
						<v-card-subtitle>健康経営で解決した経営上の課題</v-card-subtitle>
						<v-card-text>{{ item_2.issue_content }}</v-card-text>
						<v-card-subtitle>健康経営の実施により期待する効果</v-card-subtitle>
						<v-card-text>{{ item_2.content_effect }}</v-card-text>
					</v-card>
				</v-card>
			</v-col>
		</v-row>
	</v-row><br><br>

	<v-row>
		<v-col cols="12">
			<h2>⑥ 健康経営の情報開示</h2>
		</v-col>
		<v-col cols="8">
			<h3>■ 情報開示媒体</h3>
				<v-table>
					<thead>
						<tr>
							<th class="text-left" width="20%">媒体の種別</th>
							<th class="text-left" width="30%">{{ desserts6[0].health_management_media_choices_content }}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th class="text-left">URL</th>
							<td class="text-left"><a :href="desserts6[0].health_management_URL">{{ desserts6[0].health_management_URL }}</a></td>
						</tr>
					</tbody>
				</v-table>
			<h3>■ 従業員パフォーマンス指標の開示</h3>
				<v-table>
					<tbody>
						<tr>
							<th class="text-left" width="10%">a.アブセンティーズム</th>
							<th class="text-left" width="10%">実績値</th>
							<th class="text-left" width="10%">測定方法</th>
							<th class="text-left" width="10%">複数年度分の結果</th>
							<th class="text-left" width="10%">測定範囲および回答率</th>
							<th class="text-left" width="10%">URL</th>
						</tr>
						<tr>
							<td class="text-left"></td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_a_1 }}</td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_a_2 }}</td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_a_3 }}</td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_a_4 }}</td>
							<td class="text-left"><a :href="desserts6[0].employee_performance_info_a_URL">{{ desserts6[0].employee_performance_info_a_URL }}</a></td>
						</tr>
						<tr>
							<th class="text-left" width="10%">b.プレゼンティーズム</th>
							<th class="text-left" width="10%">実績値</th>
							<th class="text-left" width="10%">測定方法</th>
							<th class="text-left" width="10%">複数年度分の結果</th>
							<th class="text-left" width="10%">測定範囲および回答率</th>
							<th class="text-left" width="10%">URL</th>
						</tr>
						<tr>
							<td class="text-left"></td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_b_1 }}</td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_b_2 }}</td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_b_3 }}</td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_b_4 }}</td>
							<td class="text-left"><a :href="desserts6[0].employee_performance_info_b_URL">{{ desserts6[0].employee_performance_info_b_URL }}</a></td>
						</tr>
						<tr>
							<th class="text-left" width="10%">c.ワークエンゲージメント</th>
							<th class="text-left" width="10%">実績値</th>
							<th class="text-left" width="10%">測定方法</th>
							<th class="text-left" width="10%">複数年度分の結果</th>
							<th class="text-left" width="10%">測定範囲および回答率</th>
							<th class="text-left" width="10%">URL</th>
						</tr>
						<tr>
							<td class="text-left"></td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_c_1 }}</td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_c_2 }}</td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_c_3 }}</td>
							<td class="text-left">{{ desserts6[0].employee_performance_info_c_4 }}</td>
							<td class="text-left"><a :href="desserts6[0].employee_performance_info_c_URL">{{ desserts6[0].employee_performance_info_c_URL }}</a></td>
						</tr>
					</tbody>
				</v-table>
		</v-col>

	</v-row><br><br>
</template>
<script>

import axios from "axios";

		export default {
			props: ["companyInfo"],
			data: () => ({
				tab: null,
				desserts5: [
					{
						choices_content: '',
						issue_content:
							"",
						content_effect:
							"",
					},
					{
						choices_content: '',
						issue_content: '',
						content_effect: '',
					},
				],
				desserts5_1_reference: [
					{
						corporate_name:"",
						code:"",
						industry:"",
						score:"",
						issue_content: "",
						content_effect:"",
					},
					{
						corporate_name:"",
						code:"",
						industry:"",
						score:"",
						issue_content: "",
						content_effect:"",
					},
					{
						corporate_name:"",
						code:"",
						industry:"",
						score:"",
						issue_content: "",
						content_effect:"",
					},
					{
						corporate_name:"",
						code:"",
						industry:"",
						score:"",
						issue_content: "",
						content_effect:"",
					},
					{
						corporate_name:"",
						code:"",
						industry:"",
						score:"",
						issue_content: "",
						content_effect:"",
					}
				],
				desserts5_2_reference: [
					{
						corporate_name:"",
						code:"",
						industry:"",
						score:"",
						issue_content: "",
						content_effect:"",
					},
					{
						corporate_name:"",
						code:"",
						industry:"",
						score:"",
						issue_content: "",
						content_effect:"",
					},
					{
						corporate_name:"",
						code:"",
						industry:"",
						score:"",
						issue_content: "",
						content_effect:"",
					},
					{
						corporate_name:"",
						code:"",
						industry:"",
						score:"",
						issue_content: "",
						content_effect:"",
					},
					{
						corporate_name:"",
						code:"",
						industry:"",
						score:"",
						issue_content: "",
						content_effect:"",
					}
				],
				desserts6: [
					{
						health_management_media_choices_content: '',
						health_management_URL:"",
						employee_performance_info_a_1:"",
						employee_performance_info_a_2:"",
						employee_performance_info_a_3:"",
						employee_performance_info_a_4:"",
						employee_performance_info_b_1:"",
						employee_performance_info_b_2:"",
						employee_performance_info_b_3:"",
						employee_performance_info_b_4:"",
						employee_performance_info_c_1:"",
						employee_performance_info_c_2:"",
						employee_performance_info_c_3:"",
						employee_performance_info_c_4:"",
						employee_performance_info_a_URL:"",
						employee_performance_info_b_URL:"",
						employee_performance_info_c_URL:"",
					},
				],
			}),
			methods: {
			},
			mounted() {
				axios
					.get('https://welcomeapi.armdxp.com/DETAIL_2?code=' + this.companyInfo.code)
					.then((response) => {
						this.desserts5[0].choices_content = response.data.health_management_issue_choices_content_1
						this.desserts5[0].issue_content = response.data.health_management_issue_content_1
						this.desserts5[0].content_effect = response.data.health_management_issue_content_effect_1
						this.desserts5[1].choices_content = response.data.health_management_issue_choices_content_2
						this.desserts5[1].issue_content = response.data.health_management_issue_content_2
						this.desserts5[1].content_effect = response.data.health_management_issue_content_effect_2
						let health_management_issue_answer_lists_1 = response.data.health_management_issue_answer_ls_1
						let num_1 = 0
						for (let answer_ls_0 of health_management_issue_answer_lists_1) {
							this.desserts5_1_reference[num_1].corporate_name = answer_ls_0[0];
							this.desserts5_1_reference[num_1].code = answer_ls_0[1];
							this.desserts5_1_reference[num_1].industry = answer_ls_0[2];
							this.desserts5_1_reference[num_1].score = answer_ls_0[3];
							this.desserts5_1_reference[num_1].issue_content = answer_ls_0[4];
							this.desserts5_1_reference[num_1].content_effect = answer_ls_0[5];
							num_1 += 1
						}
						let health_management_issue_answer_lists_2 = response.data.health_management_issue_answer_ls_2
						let num_2 = 0
						for (let answer_ls_1 of health_management_issue_answer_lists_2) {
							this.desserts5_2_reference[num_2].corporate_name = answer_ls_1[0];
							this.desserts5_2_reference[num_2].code = answer_ls_1[1];
							this.desserts5_2_reference[num_2].industry = answer_ls_1[2];
							this.desserts5_2_reference[num_2].score = answer_ls_1[3];
							this.desserts5_2_reference[num_2].issue_content = answer_ls_1[4];
							this.desserts5_2_reference[num_2].content_effect = answer_ls_1[5];
							num_2 += 1
						}
						this.desserts6[0].health_management_media_choices_content = response.data.health_management_media_choices_content
						this.desserts6[0].health_management_URL = response.data.health_management_URL
						this.desserts6[0].employee_performance_info_a_1 = response.data.employee_performance_info_a_1 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_a_2 = response.data.employee_performance_info_a_2 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_a_3 = response.data.employee_performance_info_a_3 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_a_4 = response.data.employee_performance_info_a_4 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_b_1 = response.data.employee_performance_info_b_1 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_b_2 = response.data.employee_performance_info_b_2 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_b_3 = response.data.employee_performance_info_b_3 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_b_4 = response.data.employee_performance_info_b_4 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_c_1 = response.data.employee_performance_info_c_1 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_c_2 = response.data.employee_performance_info_c_2 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_c_3 = response.data.employee_performance_info_c_3 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_c_4 = response.data.employee_performance_info_c_4 ? "〇" : "×";
						this.desserts6[0].employee_performance_info_a_URL = response.data.employee_performance_info_a_URL
						this.desserts6[0].employee_performance_info_b_URL = response.data.employee_performance_info_b_URL
						this.desserts6[0].employee_performance_info_c_URL = response.data.employee_performance_info_c_URL
				}
				)
			},
		}
</script>
